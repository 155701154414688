import React from 'react'
import { Helmet } from 'react-helmet'

import './../assets/css/fondateurs.css'

import elguitti from './../assets/img/elguitti.svg'
import mohammed from './../assets/img/mohammed.svg'
import farrouj from './../assets/img/farrouj.svg'
import subtitleBlue from './../assets/img/subtitle_blue_stroke.svg'
import littleEmblemBlue from './../assets/img/little_emblem_blue.svg'

const Fondateurs = () => {
  return (
    <>
      <Helmet>
        <title>Fondateurs | Paramedic</title>
      </Helmet>
      <section id='founders'>
        <div className='row title-container'>
          <h1>Les fondateurs</h1>
          <img src={subtitleBlue} alt='Traits bleus à bouts arondis' />
        </div>
        <div className='row main-container'>
          <div className='col-md-3'>
            <img className='portrait' src={elguitti} alt="Illustration d'un des fondateurs de Paramedic" />
            <p className='prenom'>Khalid</p>
            <p className='nom'>EL GUITTI</p>
            <img className='strokes' src={subtitleBlue} alt='Traits bleus à bouts arondis' />
            <p className='initiales_fonction'>CEO</p>
            <p className='fonction'>Chief Executive Officer</p>
            <img className='embleme' src={littleEmblemBlue} alt='Petite emblème Paramedic' />
          </div>
          <div className='col-md-3'>
            <img className='portrait' src={mohammed} alt="Illustration d'un des fondateurs de Paramedic" />
            <p className='prenom'>Yassine</p>
            <p className='nom'>KHELIFA</p>
            <img className='strokes' src={subtitleBlue} alt='Traits bleus à bouts arondis' />
            <p className='initiales_fonction'>CTO</p>
            <p className='fonction'>Chief Technology Officer</p>
            <img className='embleme' src={littleEmblemBlue} alt='Petite emblème Paramedic' />
          </div>
          <div className='col-md-3'>
            <img className='portrait' src={farrouj} alt="Illustration d'un des fondateurs de Paramedic" />
            <p className='prenom'>Chaimaa</p>
            <p className='nom'>FARROUJ</p>
            <img className='strokes' src={subtitleBlue} alt='Traits bleus à bouts arondis' />
            <p className='initiales_fonction'>CSO</p>
            <p className='fonction'>Chief Strategy Officer</p>
            <img className='embleme' src={littleEmblemBlue} alt='Petite emblème Paramedic' />
          </div>
        </div>
      </section>

    </>
  )
}

export default Fondateurs
